import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js"; // You'll need to install stripe using npm install @stripe/stripe-js
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import DataTable from "./DataTable";
import config from "../config/config";
import Loading from "./Loading";
import { convertTo24Hour } from "../utils/helper";

const stripePromise = loadStripe(config.stripeToken);

function Home() {
    const [originalRaceData, setOriginalRaceData] = useState([]);
    const [displayedRaceData, setDisplayRaceData] = useState([]);
    const [venueList, setVenueList] = useState(["All"]);
    const [selectedVenue, setSelectedVenue] = useState("All");
    const [isValuedChecked, setIsValuedChecked] = useState(true);
    const [isRatedChecked, setIsRatedChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isAUNZChecked, setIsAUNZChecked] = useState(true);
    const [isIntChecked, setIsIntChecked] = useState(true);

    const preprocessData = (data) =>
        data.map((item) => ({
            ...item,
            "Win $": item["Win $"] === 0 ? "-" : item["Win $"],
            "Place $": item["Place $"] === 0 ? "-" : item["Place $"],
        }));

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view

        const fetchRatedData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${config.dataUrl}/R/rated`);
                const data = await response.json();

                const ratedData = preprocessData(
                    data.map((item) => ({
                        ...item,
                        Type: ["Rated"],
                    }))
                );

                setOriginalRaceData((prevDataArray) => {
                    const updatedDataArray = [...prevDataArray];

                    ratedData.forEach((newData) => {
                        const existingIndex = updatedDataArray.findIndex(
                            (item) => item.Horse === newData.Horse
                        );
                        if (existingIndex !== -1) {
                            // Update existing item
                            if (
                                !updatedDataArray[existingIndex]["Type"].includes(
                                    newData.Type[0]
                                )
                            ) {
                                updatedDataArray[existingIndex]["Type"].push(
                                    newData.Type[0]
                                );
                            }
                        } else {
                            // Append new item
                            updatedDataArray.push(newData);
                        }
                    });

                    const uniqueVenues = Array.from(
                        new Set(updatedDataArray.map((item) => item.Venue))
                    );

                    setVenueList(uniqueVenues);
                    const sortedData = updatedDataArray.sort((a, b) => {
                        const timeA = convertTo24Hour(a.Time);
                        const timeB = convertTo24Hour(b.Time);
                        return timeA.localeCompare(timeB);
                    });

                    setDisplayRaceData(sortedData);
                    setIsLoading(false);

                    return sortedData;
                });
            } catch (error) {
                console.error("Error fetching rated data:", error);
            }
        };

        const fetchValueData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${config.dataUrl}/R/value`);
                const data = await response.json();

                const valueData = preprocessData(
                    data.map((item) => ({
                        ...item,
                        Type: ["Value"],
                    }))
                );

                setOriginalRaceData((prevDataArray) => {
                    const updatedDataArray = [...prevDataArray];

                    valueData.forEach((newData) => {
                        const existingIndex = updatedDataArray.findIndex(
                            (item) => item.Horse === newData.Horse
                        );
                        if (existingIndex !== -1) {
                            // Update existing item
                            if (
                                !updatedDataArray[existingIndex]["Type"].includes(
                                    newData.Type[0]
                                )
                            ) {
                                updatedDataArray[existingIndex]["Type"].push(
                                    newData.Type[0]
                                );
                            }
                        } else {
                            // Append new item
                            updatedDataArray.push(newData);
                        }
                    });

                    const uniqueVenues = Array.from(
                        new Set(updatedDataArray.map((item) => item.Venue))
                    );

                    setVenueList(uniqueVenues);
                    const sortedData = updatedDataArray.sort((a, b) => {
                        const timeA = convertTo24Hour(a.Time);
                        const timeB = convertTo24Hour(b.Time);
                        return timeA.localeCompare(timeB);
                    });
                    setDisplayRaceData(sortedData);
                    setIsLoading(false);
                    return sortedData;
                });
            } catch (error) {
                console.error("Error fetching Value data:", error);
            }
        };

        fetchRatedData();
        fetchValueData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "Time",
                accessor: "Time",
                Class: "card-time",
            },
            {
                Header: "Venue",
                accessor: "Venue",
                Class: "card-venue",
            },
            {
                Header: "Region",
                accessor: "Region",
            },
            {
                Header: "Race",
                accessor: "Race",
                Class: "card-race",
            },
            {
                Header: "Tip",
                accessor: "Tip",
                Class: "card-tip",
            },
            {
                Header: "Horse",
                accessor: "Horse",
                Class: "card-horse",
            },
            {
                Header: "Result",
                accessor: "Result",
            },
            {
                Header: "Win $",
                accessor: "Win $",
                Class: "card-win",
            },
            {
                Header: "Place $",
                accessor: "Place $",
                Class: "card-place",
            },
            {
                Header: "Runners",
                accessor: "Runners",
            },
            {
                Header: "Distance",
                accessor: "Distance",
            },

            {
                Header: "Type",
                accessor: "Type",
                Class: "card-type",
            },
        ],
        []
    );

    const handleButtonClick = async (event) => {
        const stripe = await stripePromise;
        const mode = event.target.dataset.checkoutMode;
        const priceId = event.target.dataset.priceId;
        const items = [{ price: priceId, quantity: 1 }];

        const DOMAIN = window.location.href.replace(/[^/]*$/, "");

        stripe
            .redirectToCheckout({
                mode,
                lineItems: items,
                successUrl: `${DOMAIN}success?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${DOMAIN}index.html?session_id={CHECKOUT_SESSION_ID}`,
            })
            .then((result) => {
                if (result.error) {
                    const displayError =
                        document.getElementById("error-message");
                    displayError.textContent = result.error.message;
                }
            });
    };

    const updateRaceData = (venue, valueData, ratedData, aunz, international) => {
        const tempRaceData = originalRaceData.filter((item) => {
            let tmpReturn = true
            if (item.Venue !== venue && venue !== "All") {
                return false;
            }

            if (valueData && item.Type.includes("Value")) {
                tmpReturn = true;
            } else if (ratedData && item.Type.includes("Rated")) {
                tmpReturn = true;
            } else { 
                tmpReturn =  false 
            }

            // Handle Region Selection
            if (tmpReturn && aunz && item.Region == "AU/NZ") {
                tmpReturn =  true;
            } else if (tmpReturn && international && item.Region == "Int") {
                tmpReturn =  true;
            }  else { 
                tmpReturn =  false 
            }

            return tmpReturn;
        });

        setDisplayRaceData(tempRaceData);
    };

    const handleVenueChange = (event) => {
        setSelectedVenue(event.target.value);
        updateRaceData(event.target.value, isValuedChecked, isRatedChecked,isAUNZChecked,isIntChecked);
    };

    const handleValueDataChange = () => {
        setIsValuedChecked((prevState) => {
            updateRaceData(selectedVenue, !prevState, isRatedChecked,isAUNZChecked,isIntChecked);

            return !prevState;
        });
    };

    const handleRatedDataChange = () => {
        setIsRatedChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, !prevState,isAUNZChecked,isIntChecked);
            return !prevState;
        });
    };

    const handleAUNZChange = () => {
        setIsAUNZChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, isRatedChecked,!prevState,isIntChecked);
            return !prevState;
        });
    };

    const handleIntChange = () => {
        setIsIntChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, isRatedChecked,isAUNZChecked,!prevState);
            return !prevState;
        });
    };

    return (
        <div className="container">
            {isLoading ? (
                <Loading />
            ) : (
                <section
                    className="ctext section-body"
                    id="header1-0"
                    data-rv-view="0"
                >
                    <div className="filter-container">
                        <h1 className="table-header">Today's Free Tips</h1>
                        <div className="filter-section">
                            <select
                                className="filter-dropdown"
                                onChange={handleVenueChange}
                            >
                                <option value="All">All</option>
                                {venueList.map((venue) => {
                                    return (
                                        <option value={venue} key={venue}>
                                            {venue}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="filter-group">
                                <div className="filter-checkbox">
                                    <label className="switch-label aunz">
                                        AU/NZ
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isAUNZChecked}
                                            onChange={handleAUNZChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label int">
                                        Int.
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isIntChecked}
                                            onChange={handleIntChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label valued">
                                        Value
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isValuedChecked}
                                            onChange={handleValueDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label rated">
                                        Rated
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isRatedChecked}
                                            onChange={handleRatedDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-table-cell align-content-center ctext">
                        <div className="container">
                            <div className="">
                                <DataTable
                                    data={displayedRaceData}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>

                    <div id="error-message"></div>
                    <div className="modal"></div>
                </section>
            )}
        </div>
    );
}

export default Home;
